// src/components/LandingPage.js
import React from 'react';
import './LandingPage.css';
import appStoreBadge from './assets/app-store-logo.png'; // Placeholder paths for store badges
import playStoreBadge from './assets/playstore.png'; // Replace with actual paths

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header className="header">
        <h1>Pass Your Ontario G1 Test with Ease</h1>
        <p>Download our app to access the best G1 test preparation materials.</p>
        <div className="store-buttons">
          <a href="https://apps.apple.com/us/app/ontario-g1-test-2024/id6648798401" target="_blank" rel="noopener noreferrer">
            <img src={appStoreBadge} alt="Download on the App Store" />
          </a>
          <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
            <img src={playStoreBadge} alt="Get it on Google Play" />
          </a>
        </div>
      </header>
      <section className="features">
        <h2>Why Choose Our App?</h2>
        <div className="feature-list">
          <div className="feature-item">
            <h3>Comprehensive Study Materials</h3>
            <p>All the content you need to pass the G1 test on your first try.</p>
          </div>
          <div className="feature-item">
            <h3>Realistic Practice Tests</h3>
            <p>Simulate the actual G1 test with our up-to-date practice exams.</p>
          </div>
          <div className="feature-item">
            <h3>Progress Tracking</h3>
            <p>Keep track of your preparation with detailed analytics.</p>
          </div>
        </div>
      </section>
      <footer className="footer">
        <p>&copy; 2024 G1 Test Prep App. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
