import React from 'react';

const EarsPalPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Privacy Policy for EarsPal</h1>
      <p>Last updated: October 22, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to EarsPal. This Privacy Policy explains how we collect, use, and protect your information when you use our app.
        By using EarsPal, you agree to the collection and use of your data in accordance with this policy.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        EarsPal uses the iOS Speech Recognizer to collect voice recordings and convert them to text. We do not store any audio
        data or voice recordings on our servers. All recordings are processed locally on your device using iOS services.
      </p>

      <h2>3. Use of Information</h2>
      <p>
        Once your voice recording is converted to text, we use the Gemini model to analyze the text for enhanced insights.
        The text analysis is performed within the app, and no personal information or data is sent to external servers.
      </p>

      <h2>4. Data Security</h2>
      <p>
        We prioritize your data security and ensure that your voice recordings and text data are processed securely. 
        EarsPal does not store or share any of your personal information or voice data with third parties.
      </p>

      <h2>5. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
        You are advised to review this page periodically for any changes.
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at: itisourlives@gmail.com
      </p>
    </div>
  );
};

export default EarsPalPolicy;
