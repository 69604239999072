import React from 'react';

const PrivacyPolicy = () => {
  
  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Privacy Policy</h1>
      <p>Last updated: {formattedDate}</p>

      <p>
        Welcome to Itsourlives! This privacy policy outlines how we collect, use, and protect your information when you use the Ontario G1 test 2024 app.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We collect the following types of information:
      </p>
      <ul>
        <li><strong>Personal Information:</strong> When you sign up, we may collect personal details such as your email address.</li>
        <li><strong>Usage Data:</strong> We may collect information on how you access and use the service, including your device’s IP address, browser type, and other analytics data.</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>
        We use the collected information to:
      </p>
      <ul>
        <li>Provide and maintain our service.</li>
        <li>Improve, personalize, and expand our service.</li>
        <li>Communicate with you, either directly or through one of our partners.</li>
        <li>Prevent fraud and ensure the security of our service.</li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>
        We do not share your personal information with third parties except in the following circumstances:
      </p>
      <ul>
        <li>With service providers who assist in our operations.</li>
        <li>When required by law or to respond to legal requests.</li>
        <li>To protect the rights, property, or safety of itisourlives, our users, or others.</li>
      </ul>

      <h2>Security</h2>
      <p>
        We take the security of your personal information seriously and use commercially acceptable means to protect it. However, no method of transmission over the internet is 100% secure.
      </p>

      <h2>Your Data Rights</h2>
      <p>
        You have the right to access, update, or delete your personal information. To exercise these rights, please contact us at  <a href="mailto:itisourlives@gmail.com?subject=Hello">
        itisourlives@gmail.com
      </a>
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this privacy policy, please contact us: <a href="mailto:itisourlives@gmail.com?subject=Hello">
        itisourlives@gmail.com
      </a> 
      </p>
      <ul>
        <li>By email: <a href="mailto:itisourlives@gmail.com?subject=Hello">
        itisourlives@gmail.com
      </a> </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
